import { uniqueId } from "lodash"
import React from "react"
import styled from "styled-components"
import { Theme, ThemeContext } from "./theme"



const FieldContainer = styled.div`
  margin-bottom: 1rem;
`

const TextFieldLabel = styled.label`
  display: inline-block;
  margin-bottom: .5rem;
`

const TextFieldLabelRequired = styled.span`
  color: red;
  margin-left: 5px;
`

const TextFieldInput = styled.input.attrs( {
  className: "Form_TextFieldInput",
} )`
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: .375rem .75rem;
  transition-duration: 150ms;
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out;
  width: 100%;
`

interface TextFieldInputUnderLine {
  inputStyleColor: string
}

const TextFieldInputUnderline = styled.input<TextFieldInputUnderLine>`
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 2px solid ${ ( props ) => props.inputStyleColor };
  color: #495057;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: .375rem .75rem;
  transition-duration: 150ms;
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out;
  width: 100%;
`

export interface TextFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
  multiline?: boolean
  rows?: string
  labelStyle?: React.CSSProperties
  requiredStyle?: React.CSSProperties
  inputStyle?: "standard" | "line"
  inputStyleColor?: string
}

export const TextField = ( props: TextFieldProps ) => {
  const { id, label, multiline, name, inputStyle, inputStyleColor } = props
  const inputId = id || uniqueId( name )

  let inputProps = { type: "text", ...props } as any

  if( multiline ) {
    inputProps = {
      ...props,
      // https://www.styled-components.com/docs/api#as-polymorphic-prop
      as: "textarea",
    }
  }

  return (
    <FieldContainer>
      <TextFieldLabel htmlFor={ inputId } style={ props.labelStyle }>
        { label }
        { props.required && <TextFieldLabelRequired style={ props.requiredStyle }>*</TextFieldLabelRequired> }
      </TextFieldLabel>
      { inputStyle === "line" ? (
          <TextFieldInputUnderline id={ inputId } { ...inputProps } lineColor={ inputStyleColor } />
        ) : (
          <TextFieldInput id={ inputId } { ...inputProps } />
        )
      }
    </FieldContainer>
  )
}

// tslint:disable-next-line: space-within-parens
const CheckboxFieldContainer = styled(FieldContainer)`
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
`

const CheckboxFieldLabel = styled.label`
  padding-left: 10px;
  cursor: pointer;
`

const CheckboxFieldInput = styled.input.attrs( {
  type: "checkbox"
} )`
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  color: white;
  background-color: #fff;
  border-color: white;
  border-width: 1px;
  border-radius: 0.25rem;

  :checked {
    background-image: url( "data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='${ ( props: CheckboxFieldProps ) => encodeURIComponent( props.color ) }' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e" );
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export interface CheckboxFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  color: string
  label: string
  labelStyle?: React.CSSProperties
}

export const CheckboxField = ( props: TextFieldProps ) => {
  const { id, label, name } = props
  const inputId = id || uniqueId( name )
  const inputProps = { ...props } as any

  return (
    <ThemeContext.Consumer>
      { ( theme: Theme ) => (
        <CheckboxFieldContainer>
          <CheckboxFieldInput id={ inputId } color={ props.color || theme.primaryColor } { ...inputProps }/>
          <CheckboxFieldLabel htmlFor={ inputId } style={ props.labelStyle }>{ label }</CheckboxFieldLabel>
        </CheckboxFieldContainer>
      ) }
    </ThemeContext.Consumer>
  )
}
