import React, { ReactNode } from "react"
// import { Helmet } from "react-helmet"
import { BaseLayout, NavBarContainer } from "shared/components"
import { Theme } from "shared/components/theme"
import styled from "styled-components"
import logo from "../images/logo.png"

require( "../styles/import.scss" )

const Container = styled.div`
  margin: 50px 100px;
`
const LogoContainer = styled.a`
  display: inline-block;
  max-width: 200px;
`

const Logo = styled.img`
  width: 100%;
`

const Footer = styled.div`
  border-top: solid 1px #333;
  padding: 15px;
  text-align: center;
`

const FooterP = styled.p`
  font-size: 0.9rem;
`
const Link = styled.a`
  color: #f15f4a;

  :hover {
    color: #bf4b3c;
  }
`

const Bold = styled.span`
  font-weight: bold;
`

export interface LayoutProps {
  children: ReactNode
}

export const Layout: React.FunctionComponent<LayoutProps> = ( { children }: LayoutProps ) => {
  const primaryColor = "#337930"

  const theme: Theme = {
    primaryColor,
    secondaryColor: "#337930",
  }

  return (
    <BaseLayout theme={ theme }>
      <NavBarContainer
        backgroundColor="#eee"
        size="full"
      >
        <div className="w-full py-6 px-10">
          <LogoContainer href="https://onelocal.com/" target="_blank">
            <Logo src={ logo } />
          </LogoContainer>
        </div>
      </NavBarContainer>

      <Container>
        { children }
      </Container>
      <Footer>
        <FooterP>Copyright &copy; 2020 <Link href="https://onelocal.com/" target="_blank">One<Bold>Local</Bold></Link>. All Rights Reserved</FooterP>
      </Footer>
    </BaseLayout>
  )
}
