import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"

import { ThemeContext } from "."
import { Theme } from "./theme"

export interface BaseLayout {
  children: ReactNode
  // Will render the element in the header
  // https://reactjs.org/docs/render-props.html
  renderHead?: () => React.ReactNode
  theme: Theme
}

export const BaseLayout = ( { children, renderHead, theme }: BaseLayout ) => (
  <>
    <Helmet htmlAttributes={ { lang: "en" } }>
      { renderHead ? renderHead() : null }
    </Helmet>
    <ThemeContext.Provider value={ theme }>{ children }</ThemeContext.Provider>
  </>
)
