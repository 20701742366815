import Img, { FluidObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"

import { Slider } from "."

// @ToDo - Fix lightbox functionality of slider

const SlideContainer = styled.div`

`

// tslint:disable-next-line: space-within-parens
const SlideImg = styled(Img)`
  max-height: 100% !important;
`

const SlideImgContainer = styled.div`
  height: 320px !important;
`

// const SlideLightboxBackground = styled.div`
//   align-items: center;
//   background: rgba(0,0,0,0.2);
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   left: 0;
//   position: fixed;
//   right: 0;
//   top: 0;
// `

// const SlideLightboxImgContainer = styled.div`
//   height: 50%;
//   width: 50%;
// `

interface SlideProps {
  image?: FluidObject
  renderSlideContent?: () => React.ReactNode
  // showLightbox: () => void | string
  // name: string
}


const Slide: React.FunctionComponent<SlideProps> = ( props: SlideProps ) => {

  const { image, renderSlideContent } = props

  return(
    <>
      <SlideContainer>
        <SlideImgContainer
          // data-name={ name }
          // onClick={ showLightbox }
        >
          <SlideImg fluid={ image } />
        </SlideImgContainer>
        { renderSlideContent ? renderSlideContent() : null }
      </SlideContainer>
    </>
  )
}

interface LightboxSliderProps {
  // onClick: () => void | string
  slides: SlideProps[]
  // value: string
}

// interface LightboxSliderState {
//   isShown: boolean,
//   selectedImage: FluidObject
// }


export class LightboxSlider extends React.Component<LightboxSliderProps & SlideProps> {

  // state = {
  //   isShown: false,
  //   selectedImage: null,
  // }

  // showLightbox = ( ) => {
  //   this.setState( {
  //     isShown: !this.state.isShown,
  //     selectedImage: this.data.name
  //   } )
  // }

  // hideLightbox = ( ) => {
  //   this.setState( {
  //     isShown: !this.state.isShown,
  //     selectedImage: null
  //   } )
  // }

  render() {

    const { slides } = this.props
    // const { isShown, selectedImage } = this.state

    return (
      <>
        <Slider
          slides={ slides.map( ( slide, i ) => (
            <div key={ i } id={ i.toString() }>
              <Slide
                image={ slide.image }
                // name={ slide.name }
                // showLightbox={ this.showLightbox }
                renderSlideContent={ slide.renderSlideContent }
              />
            </div>
          ) )
          }
          chevronColor={ "black" }
          slidesToShow={ 4 }
        />
        { /* { isShown ? slides.map( ( slide, j ) => (
          <SlideLightboxBackground
            key={ j }
            // tslint:disable-next-line: jsx-no-lambda
            onClick={ this.hideLightbox }
            data-name={ slide.name }
          >
              <SlideLightboxImgContainer
                // tslint:disable-next-line: jsx-no-lambda
                onClick={ this.hideLightbox }
              >
                <Img fluid={ slide.image } />
              </SlideLightboxImgContainer>
          </SlideLightboxBackground>
         ) ) : null
        } */ }
      </>
    )
  }
}
