import React from "react"
import { createMediaQueryMatcher } from "../helpers/responsive_helpers"

interface ResponsiveContentSwitcherProps {
  mediaQuery: string
  renderAboveMediaQuery: () => React.ReactNode
  renderBelowMediaQuery: () => React.ReactNode
}

interface ResponsiveContentSwitcherState {
  isBelowBreakPoint: boolean
}

export class ResponsiveContentSwitcher extends React.PureComponent<ResponsiveContentSwitcherProps, ResponsiveContentSwitcherState> {
  constructor( props: ResponsiveContentSwitcherProps ) {
    super( props )
    this.state = {
      isBelowBreakPoint: false,
    }
  }

  componentDidMount() {
    window.addEventListener( "resize", this.handleResize )
    this.getIsBelowBreakPoint()
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.handleResize )
  }

  // tslint:disable-next-line: member-ordering
  isGreaterThanBreakPoint = createMediaQueryMatcher( this.props.mediaQuery )

  getIsBelowBreakPoint = () => {
    return ! this.isGreaterThanBreakPoint()
  }

  handleResize = () => {
    this.setState( {
      isBelowBreakPoint: this.getIsBelowBreakPoint()
    } )
  }

  render() {
    if( this.state.isBelowBreakPoint ) {
      return (
        <>
          { this.props.renderBelowMediaQuery() }
        </>
      )
    }
    return (
      <>
        { this.props.renderAboveMediaQuery() }
      </>
    )
  }
}
