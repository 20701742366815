import React, { ReactNode } from "react"
import styled from "styled-components"
import { LARGE_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"

interface ContainerProps {
  overflowHidden?: boolean
}

const Container = styled.div<ContainerProps>`
  left: 0;
  overflow: ${ ( props: ContainerProps ) => props.overflowHidden ? "hidden" : "visible" };
  position: sticky;
  right: 0;
  transition: box-shadow 0.25s ease-in-out;
  top: 0;
  z-index: 1000;
`

interface WrapperProps {
  size: "full" | "contain"
}

const Wrapper = styled.div<WrapperProps>`
  margin: 0 auto;
  padding: 0 1rem;

  @media ${ LARGE_SCREEN_MEDIA_QUERY}  {
    max-width: 95%;
    width: ${ ( props ) => props.size === "contain" ? `1140px` : `auto` };
  }
`

interface NavBarContainerProps {
  size?: "full" | "contain"
  backgroundColor?: string
  backgroundImage?: string
  children: ReactNode
  onScrollStyle?: ( scrollPercent: number ) => React.CSSProperties | null
  overflowHidden?: boolean
}

interface NavBarContainerState {
  scrollPercent: number
}


export class NavBarContainer extends React.PureComponent<NavBarContainerProps, NavBarContainerState> {
  wrapperRef: React.RefObject<HTMLDivElement>

  constructor( props: NavBarContainerProps ) {
    super( props )
    this.wrapperRef = React.createRef()
    this.state = {
      scrollPercent: 0,
    }
  }

  componentDidMount() {
    window.addEventListener( "scroll", this.handleScroll )
  }

  componentWillUnmount() {
    window.removeEventListener( "scroll", this.handleScroll )
  }

  handleScroll = () => {
    if ( !this.wrapperRef || !this.wrapperRef.current ) {
      return
    }

    const containerHeight = this.wrapperRef.current.clientHeight
    const scrollTopPosition = ( window.pageYOffset || ( document as any ).scrollTop || 0 ) - ( ( document as any ).clientTop || 0 )
    const scrollPercent = Math.min( Math.max( scrollTopPosition, 0 ), containerHeight ) / containerHeight

    this.setState( { scrollPercent } )
  }

  render() {
    const { backgroundColor, backgroundImage, children, onScrollStyle, overflowHidden, size } = this.props
    const { scrollPercent } = this.state

    let style: React.CSSProperties = {}

    if ( backgroundColor ) {
      style.backgroundColor = backgroundColor
    }

    if ( backgroundImage ) {
      style.backgroundImage = `url( "${ backgroundImage }" )`
    }

    if ( scrollPercent > 0 ) {
      style.boxShadow = "0px 7px 14px 0px rgba(0, 0, 0, 0.25)"
    }

    if ( onScrollStyle ) {
      style = {
        ...style,
        ...onScrollStyle( scrollPercent )
      }
    }

    return (
      <Container ref={ this.wrapperRef } style={ style } overflowHidden={ overflowHidden }>
        <Wrapper size={ size || "contain" }>
          { children }
        </Wrapper>
      </Container>
    )
  }
}
