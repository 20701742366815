import React from "react"
import styled from "styled-components"
import { LARGE_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"

interface ContainerProps {
  color: string
  imageOn: "right" | "left"
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column-reverse;

  @media ${ LARGE_SCREEN_MEDIA_QUERY} {
    flex-direction: ${ ( props ) => props.imageOn === "left" ? "row-reverse" : "row"};
  }
`

interface ContentProps {
  color: string
}

const Content = styled.div<ContentProps>`
  align-items: center;
  background-color: ${ ( props ) => props.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height:  70vh;
  padding: 20px 4rem;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY} {
    width: 50%;
  }
`

interface BackgroundImageProps {
  backgroundImage: string
  containBackgroundImage?: boolean
}

const BackgroundImage = styled.div<BackgroundImageProps>`
  background-image: url( ${ ( props ) => props.backgroundImage} );
  background-size: ${ ( props ) => props.containBackgroundImage && "contain" || "cover" };
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY} {
    width: 50%
  }
`

interface ResponsiveContentImageProps {
  themeColor: string
  imageOn: "right" | "left"
  image: string
  content: () => React.ReactNode
  containBackgroundImage: boolean
}

export const ResponsiveContentImage = ( { image, content, themeColor, imageOn, containBackgroundImage }: ResponsiveContentImageProps ) => (
  <Container color={ themeColor } imageOn={ imageOn }>
    <Content color={ themeColor }>
      { content() }
    </Content>
    <BackgroundImage backgroundImage={ image } containBackgroundImage={ containBackgroundImage } />
  </Container>
)
