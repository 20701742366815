import { faChevronLeft, faChevronRight, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent } from "react"
import ReactSlick, { Settings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled from "styled-components"

import { UnreachableCaseError } from "../helpers/util_helper"

const SliderContainer = styled.div`
  .slick-slider {
    margin-bottom: 25px;
  }
`

const ChevronContainer = styled.button`
  opacity: 0.75;

  :before {
    display: none;
  }

  :hover {
    opacity: 1;
  }
`

enum CHEVRON_DIRECTION {
  LEFT = "left",
  RIGHT = "right",
}

interface ChevronProps {
  className?: string
  color: string
  direction: CHEVRON_DIRECTION
  hideChevron?: boolean
  isInside: boolean
  onClick?: () => void
}

const Chevron: FunctionComponent<ChevronProps> = ( props: ChevronProps ) => {
  const { className, color, direction, hideChevron, isInside } = props
  let icon: IconDefinition
  const style: React.CSSProperties = {
    height: "auto",
    width: "auto",
    zIndex: 100,
  }

  let display

  { hideChevron ? display = "none" : display = "block" }

  switch( direction ) {
    case CHEVRON_DIRECTION.LEFT:
      icon = faChevronLeft
      if( isInside ) {
        style.left = "25px"
      }
      break
    case CHEVRON_DIRECTION.RIGHT:
      icon = faChevronRight
      if( isInside ) {
        style.right = "25px"
      }
      break
    default:
      throw new UnreachableCaseError( direction )
  }

  return (
    <ChevronContainer className={ className } onClick={ props.onClick } style={ style }>
      <FontAwesomeIcon
        icon={ icon }
        style={ {
          color,
          display,
          fontSize: "24px",
        } }
      />
    </ChevronContainer>
  )
}


export interface SliderProps {
  autoplay?: boolean
  chevronColor: string
  chevronInside?: boolean
  dotsClass?: string
  hideChevron?: boolean
  responsive?: Array<{
    breakpoint: number,
    slidesToShow: number
  }>
  slides: React.ReactNode[]
  slidesToScroll?: number
  slidesToShow: number
}

export const Slider: FunctionComponent<SliderProps> = ( props: SliderProps ) => {
  const { autoplay, chevronColor, chevronInside, dotsClass, hideChevron, responsive, slides, slidesToScroll, slidesToShow } = props

  const settings: Settings = {
    dots: true,
    dotsClass: `slick-dots ${ dotsClass ? dotsClass : null }`,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToScroll ? slidesToScroll : 1,
    swipeToSlide: ( autoplay || false ),
    prevArrow: <Chevron direction={ CHEVRON_DIRECTION.LEFT } color={ chevronColor } hideChevron={ hideChevron } isInside={ chevronInside || false } />,
    nextArrow: <Chevron direction={ CHEVRON_DIRECTION.RIGHT } color={ chevronColor } hideChevron={ hideChevron } isInside={ chevronInside || false } />,
  }

  if( responsive ) {
    settings.responsive = responsive.map( ( responsiveItem ) => (
      {
        breakpoint: responsiveItem.breakpoint,
        settings: {
          slidesToShow: responsiveItem.slidesToShow,
        }
      }
    ) )
  }

  return (
    <SliderContainer>
      <ReactSlick { ...settings }>
        { slides }
      </ReactSlick>
    </SliderContainer>
  )
}
