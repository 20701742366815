import Img, { FluidObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"

import { Slider } from "."
import { EXTRA_LARGE_SCREEN_MEDIA_QUERY, isGreaterThanMediumScreen, MEDIUM_SCREEN_MEDIA_QUERY, SMALL_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"

interface SectionHeadingProps {
  color: string
}

const SectionHeading = styled.div<SectionHeadingProps>`
  border-bottom: 5px solid ${ ( props ) => props.color };
  padding-bottom: 20px;
  margin-bottom: 40px;
  max-width: 600px;
`

interface TitleProps {
  color: string
  font: string
}

const Title = styled.h2<TitleProps>`
  color: ${ ( props ) => props.color };
  font-family: ${ ( props ) => props.font };
  font-size: 1.5rem;
  margin-bottom: 1.5rem;

  @media ${ MEDIUM_SCREEN_MEDIA_QUERY } {
    font-size: 2.25rem;
  }
`

export const SubTitle = styled.h3<TitleProps>`
  color: ${ ( props ) => props.color };
  font-family: ${ ( props ) => props.font };
  font-size: 1rem;
  text-transform: uppercase;
`

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 50px;
  overflow: hidden;
  padding-left: 0;
  padding-bottom: 70px;

  @media ${ SMALL_SCREEN_MEDIA_QUERY } {
    flex-direction: row;
  }

  @media ${ MEDIUM_SCREEN_MEDIA_QUERY } {
    padding-left: 50px;
  }

  @media ${ EXTRA_LARGE_SCREEN_MEDIA_QUERY } {
    padding-left:  calc( ( 100% - 1140px ) / 2 );
  }
`

const SlideContainerText = styled.div`
  background: white;
  height: 100%;
  padding-top: 70px;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1;

  @media ${ SMALL_SCREEN_MEDIA_QUERY } {
    background: none;
    padding-bottom: 0;
    padding-right: 4rem;
    width: 50%;
    z-index: 0;
  }
`

interface SlideContainerImageProps {
  bgColor: string
}

const SlideContainerImage = styled.div<SlideContainerImageProps>`
  align-items: flex-end;
  background-image: linear-gradient( to right, transparent calc( 100% - ( 100% - 50px ) ), ${ ( props ) => props.bgColor} calc( 100% - ( 100% - 50px ) ) );
  display: flex;
  margin-top: 100px;
  padding: 150px 1rem 0;
  width: 100%;

  @media ${ SMALL_SCREEN_MEDIA_QUERY} {
    margin-top: 0;
    overflow: visible;
    width: 50%;
  }
`

interface SlideImageProps {
  beforeColor: string
  afterColor: string
}

const SlideImage = styled.div<SlideImageProps>`
  margin-bottom: 50px;
  max-width: 350px;
  position: relative;
  width: 85%;

  &:before {
    content: "";
    background-color: ${ ( props ) => props.beforeColor};
    position: absolute;
    right: 0;
    bottom: 105%;
    width: 80px;
    height: 400px;
  }

  &:after {
    content: "";
    background-color: ${ ( props ) => props.afterColor};
    position: absolute;
    left: 105%;
    bottom: 50%;
    width: 80px;
    height: 600px;
  }
`

interface SlideProps {
  title: string
  subtitle: string
  image: FluidObject
  renderSlideContent: () => React.ReactNode
  slideTheme: {
    mainBackgroundColor: string
    firstBarColor: string
    secondBarColor: string
  }
  theme?: ThemeProps
}

const Slide = ( { title, subtitle, renderSlideContent, image, slideTheme, theme }: SlideProps ) => (
  <SlideContainer>
    <SlideContainerText>
      <div>
        <SectionHeading color={ theme && theme.titleColor ? theme.titleColor : "#000" }>
          <SubTitle color={ theme && theme.subTitleColor ? theme.subTitleColor : "inherit" } font={ theme && theme.subTitleFont ? theme.subTitleFont : "inherit" }>{ subtitle }</SubTitle>
          <Title color={ theme && theme.titleColor ? theme.titleColor : "inherit" } font={ theme && theme.titleFont ? theme.titleFont : "inherit" }>{ title }</Title>
        </SectionHeading>
        { renderSlideContent() }
      </div>
    </SlideContainerText>
    <SlideContainerImage bgColor={ slideTheme.mainBackgroundColor }>
      <SlideImage beforeColor={ slideTheme.firstBarColor } afterColor={ slideTheme.secondBarColor } >
        <Img fluid={ image } />
      </SlideImage>
    </SlideContainerImage>
  </SlideContainer>
)

interface ThemeProps {
  titleColor?: string
  subTitleColor?: string
  titleFont?: string
  subTitleFont?: string
}

interface ShapesSliderProps {
  chevronInside?: boolean
  slides: SlideProps[]
  theme?: ThemeProps
}

interface ShapesSliderState {
  isMobile: boolean
}

export class ShapesSlider extends React.PureComponent<ShapesSliderProps, ShapesSliderState> {
  state = { isMobile: false }

  componentDidMount() {

    window.addEventListener( "resize", this.handleResize )
    this.getIsMobile()
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.handleResize )
  }

  handleResize = () => {
    this.setState( {
      isMobile: this.getIsMobile()
    } )
  }

  getIsMobile = () => {
    return !isGreaterThanMediumScreen()
  }

  render() {
    return (
      <>
        { this.state.isMobile ? (
          <>
            {
              this.props.slides.map( ( slide, i ) => (
                <Slide
                  key={ i }
                  title={ slide.title }
                  subtitle={ slide.subtitle }
                  image={ slide.image }
                  renderSlideContent={ slide.renderSlideContent }
                  slideTheme={ slide.slideTheme }
                  theme={ this.props.theme }
                />
              ) )
            }
          </>
        ) : (
            <Slider
              chevronColor={ this.props.theme && this.props.theme.titleColor ? this.props.theme.titleColor : "#fff" }
              chevronInside={ this.props.chevronInside || true }
              slidesToShow={ 1 }
              slides={
                this.props.slides.map( ( slide, i ) => (
                  <div key={ i }>
                    {
                      // tslint:disable-next-line: jsx-wrap-multiline
                      <Slide
                        title={ slide.title }
                        subtitle={ slide.subtitle }
                        renderSlideContent={ slide.renderSlideContent }
                        image={ slide.image }
                        slideTheme={ slide.slideTheme }
                        theme={ this.props.theme }
                      />
                    }
                  </div>
                ) )
              }
            />
          ) }
      </>
    )
  }
}
