import React from "react"

export interface ButtonTheme {
  color: string
  colorHover: string
  text: string
  textHover: string
}

export enum ButtonThemeType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

export interface Theme {
  primaryColor?: string
  secondaryColor?: string
  tertiaryColor?: string
}

export const ThemeContext = React.createContext<Theme>( {} )
