import React from "react"

export interface EmbedScriptProps extends React.HTMLProps<HTMLDivElement> {
  attributes?: { [ name: string]: string }
  onScriptLoaded?: () => void
  src: string
}

export class EmbedScript extends React.Component<EmbedScriptProps> {
  scriptAdded: boolean
  scriptContainer: React.RefObject<HTMLDivElement>

  constructor( props: EmbedScriptProps ) {
    super( props )
    this.scriptContainer = React.createRef()
    this.scriptAdded = false
  }

  componentDidMount() {
    if( ! this.scriptContainer || ! this.scriptContainer.current ) {
      console.warn( "The script container element is missing" )
      return
    }

    if( this.scriptAdded ) {
      console.warn( "The script was already added and cannot be updated" )
      return
    }

    const { attributes, onScriptLoaded, src } = this.props

    const script = document.createElement( "script" )
    script.type = "text/javascript"
    script.async = true
    script.src = src

    if( onScriptLoaded ) {
      script.onload = ( ) => {
        onScriptLoaded()
      }
    }

    if( attributes ) {
      Object.keys( attributes ).forEach( ( name ) => {
        script.setAttribute( name, attributes[ name ] )
      } )
    }

    this.scriptContainer.current.appendChild( script )

    this.scriptAdded = true
  }

  render() {
    return (
      <div ref={ this.scriptContainer } { ...this.props as any }/>
    )
  }
}
