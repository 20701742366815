/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

export interface SEOProps {
  description?: string
  meta?: Array<JSX.IntrinsicElements[ "meta" ]>
  title: string
}

export const SEO = ( { description, meta, title }: SEOProps ) => {
  meta = [
    ...( meta || [] ),
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
  ]

  if( description ) {
    meta = [
      ...meta,
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ]
  }

  return (
    <Helmet
      title={ title }
      meta={ meta }
    />
  )

}
