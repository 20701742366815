import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"

import { PrimaryButtonLink, ThemeContext } from "."
import { isGreaterThanExtraLargeScreen, isGreaterThanMediumScreen, LARGE_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"

export interface WrapperProps extends React.HTMLProps<HTMLDivElement> {
  top?: string
}

const Wrapper = styled.div.attrs( {
  className: "Wrapper",
} )<WrapperProps>`
  height: auto;
  left: 0;
  min-height: 70px;
  position: fixed;
  right: 0;
  transition: box-shadow 0.25s ease-in-out;
  top: ${ ( props ) => props.top || "0" };
  z-index: 10;
`

export interface NavWrapperProps extends React.HTMLProps<HTMLDivElement> {
  backgroundColor?: string
}

const NavWrapper = styled.div.attrs( {
  className: "NavBar_Wrapper",
} )<NavWrapperProps>`
  align-items: center;
  background-color: ${ ( props ) => props.backgroundColor || "white" };
  display: flex;
  height: auto;
  justify-content: center;
  min-height: 70px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY }  {
    height: 100px;
  }
`


const Container = styled.div.attrs( {
  className: "NavBar_Container",
} )`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: .5rem 1rem;

  @media ${ LARGE_SCREEN_MEDIA_QUERY }  {
    max-width: 1140px;
  }
`

const LogoContainer = styled.a.attrs( {
  className: "NavBar_LogoContainer",
} )`
  align-items: center;
  display: flex;
`

const Logo = styled.img.attrs( {
  className: "NavBar_Logo"
} )`
  max-height: 40px;
  max-width: 200px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    max-height: 60px;
    max-width: 250px;
  }
`

const MenuButton = styled.button.attrs( {
  className: "NavBar_MenuButton",
} )`
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 4px;
  color: rgba(0,0,0,.5);
  display: block;
  outline: 0;
  padding: .1rem .5rem;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    display: none;
  }
`

export interface MenuContainerProps extends React.HTMLProps<HTMLDivElement> {
  align?: "left" | "center" | "right"
  display: boolean
}

const MenuContainer = styled.div.attrs( {
  className: "NavBar_MenuContainer"
} )<MenuContainerProps>`
  align-items: center;
  display: ${ ( props ) => props.display ? "block" : "none" };
  flex-grow: 1;
  justify-content: ${
    ( props ) => {
      switch( props.align ) {
        case "left":
          return "flex-start"
        case "right":
          return "flex-end"
        default:
          return "center"
      }
    }
  };
  flex-basis: 100%;
  flex-direction: column;
  margin: 1rem auto;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    margin: auto;
    text-align: inherit;
  }
`

export interface LinkContainerProps extends React.HTMLProps<HTMLDivElement> {
  align?: "left" | "center" | "right"
  display?: boolean
}

const LinkContainer = styled.div.attrs( {
  className: "NavBar_LinkContainer"
} )<LinkContainerProps>`
  align-items: center;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${
    ( props ) => {
      switch( props.align ) {
        case "left":
          return "flex-start"
        case "right":
          return "flex-end"
        default:
          return "center"
      }
    }
  };
  margin: 1rem auto;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY }  {
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    margin: auto;
    text-align: inherit;
  }
`

const LinkItemWrapper = styled.div.attrs( {
  className: "NavBar_LinkItemWrapper"
} )`
  position: relative;
`

export interface LinkItemDropdownProps {
  backgroundColor?: string
}

const LinkItemDropdown = styled.div.attrs( {
  className: "NavBar_LinkItemDropdown"
} )<LinkItemDropdownProps>`
  background-color: ${ ( props ) => props.backgroundColor || "transparent" };
  border-radius: .25rem;
  font-size: 1rem;
  left: 0;
  min-width: 10rem;
  padding: .5rem 0;
  top: 100%;
  z-index: 1000;

  @media ${ LARGE_SCREEN_MEDIA_QUERY }  {
    box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.25);
    margin-top: -5px;
    position: absolute;
  }
`

export interface LinkItemDropdownLinkProps {
  backgroundColor?: string
  backgroundHoverColor?: string
  color?: string
  hoverColor?: string
}

const LinkItemDropdownLink = styled.a.attrs( {
  className: "NavBar_LinkItemDropdownLink"
} )<LinkItemDropdownLinkProps>`
  background-color: transparent;
  border: 0;
  color: ${ ( props ) => props.color || "black" };
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;

  :hover {
    background-color: ${ ( props ) => props.backgroundHoverColor || "transparent" };
    color: ${ ( props ) => props.hoverColor || "inherit" };
  }
`

export interface LinkItemLinkProps {
  backgroundHoverColor?: string
  color?: string
  hoverColor?: string
}

const LinkItemLink = styled.a.attrs( {
  className: "NavBar_LinkItemLink"
} )<LinkItemLinkProps>`
  color: ${ ( props ) => props.color || "black" };
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1rem;
  text-decoration: none;

  :hover {
    background-color: ${ ( props ) => props.backgroundHoverColor || "transparent" };
    color: ${ ( props ) => props.hoverColor || "inherit" };
    text-decoration: underline;
  }
`

export interface LinkItemProps {
  color?: string
  dropdownBackgroundColor?: string
  dropdownLinkBackgroundHoverColor?: string
  dropdownLinkColor?: string
  dropdownLinkHoverColor?: string
  hoverColor?: string
  item: NavBarItem
}

interface LinkItemState {
  isMobile?: boolean
  isOpen?: boolean
  isLargeScreen?: boolean
}

export class LinkItem extends React.PureComponent<LinkItemProps, LinkItemState> {
  constructor( props: LinkItemProps ) {
    super( props )
    this.state = {
      isMobile: false,
      isOpen: false,
      isLargeScreen: true
    }
  }

  componentDidMount() {
    window.addEventListener( "resize", this.handleResize )
    this.getIsMobile()
    this.setState( {
      isLargeScreen: this.getIsLargeScreen()
    } )
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.handleResize )
  }

  handleResize = () => {
    this.setState( {
      isMobile: this.getIsMobile(),
      isLargeScreen: this.getIsLargeScreen()
    } )
  }

  setIsOpen = ( value: boolean ) => {
    const { item } = this.props
    const { childs } = item
    if( ! childs || childs.length === 0 ) {
      return
    }

    this.setState( { isOpen: value } )
  }

  openDropdown = () => {
    this.setIsOpen( true )
  }

  closeDropdown = () => {
    this.setIsOpen( false )
  }

  toggleDropdown = () => {
    this.setState( ( prevState ) => ( { isOpen: !( prevState.isOpen ) } ) )
  }

  getIsMobile = () => {
    return ! isGreaterThanMediumScreen()
  }

  getIsLargeScreen = () => {
    return isGreaterThanExtraLargeScreen()
  }

  render() {
    const { dropdownBackgroundColor, item } = this.props
    const { isMobile, isOpen } = this.state
    const { childs, text, url } = item

    const containerProperties = isMobile ?
      {
        onClick: this.toggleDropdown,
      } :
      {
        onMouseEnter: this.openDropdown,
        onMouseLeave: this.closeDropdown,
      }

    if ( !this.state.isLargeScreen && item.isOverFlowItem ) { return null }
    if ( this.state.isLargeScreen && item.isOverFlowMenu ) { return null }

    return (
      <LinkItemWrapper { ...containerProperties }>
        <LinkItemLink
          href={ url || "#" }
          color={ this.props.color }
          backgroundHoverColor={ isMobile ? this.props.dropdownLinkBackgroundHoverColor : undefined }
          hoverColor={ this.props.hoverColor }
        >
          { text }
        </LinkItemLink>
        {
          childs && isOpen && (
            <LinkItemDropdown backgroundColor={ dropdownBackgroundColor }>
              {
                childs.map( ( child, i ) => (
                  <LinkItemDropdownLink
                    key={ i }
                    href={ child.url }
                    backgroundHoverColor={ this.props.dropdownLinkBackgroundHoverColor }
                    color={ this.props.dropdownLinkColor }
                    hoverColor={ this.props.dropdownLinkHoverColor }
                  >
                    { child.text }
                  </LinkItemDropdownLink>
                  ) )
              }
            </LinkItemDropdown>
          )
        }
      </LinkItemWrapper>
    )
  }
}

interface NavBarBase {
  text: string
  url?: string
}

export interface NavBarItem extends NavBarBase {
  childs?: NavBarBase[]
  isOverFlowItem?: boolean
  isOverFlowMenu?: boolean
}

export interface NavBarProps {
  ctaText?: string
  ctaUrl?: string
  backgroundColor?: string
  dropdownBackgroundColor?: string
  dropdownLinkBackgroundHoverColor?: string
  dropdownLinkColor?: string
  dropdownLinkHoverColor?: string
  items: NavBarItem[]
  linkColor?: string
  linkHoverColor?: string
  logoAlt?: string
  logoUrl?: string
  onScrollStyle?: ( scrollPercent: number ) => React.CSSProperties | null
  // Render custom CTA
  // See https://reactjs.org/docs/render-props.html
  renderCta?: () => React.ReactNode
  renderLogo?: () => React.ReactNode
  renderTopNav?: () => React.ReactNode
  top?: string
}

export interface NavBarState {
  isMobile?: boolean
  scrollPercent: number
  showMenu: boolean
}


export class NavBar extends React.PureComponent<NavBarProps, NavBarState> {
  wrapperRef: React.RefObject<HTMLDivElement>

  constructor( props: NavBarProps ) {
    super( props )
    this.wrapperRef = React.createRef()
    this.state = {
      scrollPercent: 0,
      showMenu: false,
    }
  }

  componentDidMount() {
    window.addEventListener( "scroll", this.handleScroll )
  }

  componentWillUnmount() {
    window.removeEventListener( "scroll", this.handleScroll )
  }

  handleScroll = () => {
    if( ! this.wrapperRef || ! this.wrapperRef.current ) {
      return
    }

    const containerHeight = this.wrapperRef.current.clientHeight
    const scrollTopPosition = ( window.pageYOffset || ( document as any ).scrollTop || 0 )  - ( ( document as any ).clientTop || 0 )
    const scrollPercent = Math.min( Math.max( scrollTopPosition, 0 ), containerHeight ) / containerHeight

    this.setState( { scrollPercent } )
  }

  toggleShowMenu = () => {
    this.setState( ( prevState ) => ( { showMenu: !( prevState.showMenu ) } ) )
  }

  render() {
    const { backgroundColor, ctaText, ctaUrl, items, linkColor, linkHoverColor, logoAlt, logoUrl, onScrollStyle, renderCta, renderLogo, renderTopNav, top } = this.props
    const { scrollPercent, showMenu } = this.state

    const cta = (
      renderCta ?
      renderCta() :
      (
        ctaText && ctaUrl &&
        <PrimaryButtonLink href={ ctaUrl }>{ ctaText }</PrimaryButtonLink>
      )
    )

    let style: React.CSSProperties = {}

    if( backgroundColor ) {
      style.backgroundColor = backgroundColor
    }

    if( scrollPercent > 0 ) {
      style.boxShadow = "0px 7px 14px 0px rgba(0, 0, 0, 0.25)"
    }

    if( onScrollStyle && ! this.state.showMenu ) {
      if( onScrollStyle ) {
        style = {
          ...style,
          ...onScrollStyle( scrollPercent )
        }
      }
    }

    return (
      <ThemeContext.Consumer>
        { ( theme ) => (
          <Wrapper ref={ this.wrapperRef } top={ top }>
            {
              renderTopNav && renderTopNav()
            }
            <NavWrapper style={ style }>
              <Container>
                {
                  renderLogo ?
                  renderLogo() :
                  (
                    logoUrl && (
                      <LogoContainer href="/">
                        <Logo src={ logoUrl } alt={ logoAlt }/>
                      </LogoContainer>
                    )
                  )
                }
                <MenuButton onClick={ this.toggleShowMenu }><FontAwesomeIcon icon={ faBars } /></MenuButton>
                <MenuContainer display={ showMenu }>
                  <LinkContainer align={ cta ? "center" : "right" }>
                    {
                      items.map( ( item, i ) => {
                        return (
                          <LinkItem
                            key={ i }
                            item={ item }
                            color={ linkColor || theme.primaryColor }
                            hoverColor={ linkHoverColor }
                            dropdownBackgroundColor={ this.props.dropdownBackgroundColor || this.props.backgroundColor }
                            dropdownLinkBackgroundHoverColor={ this.props.dropdownLinkBackgroundHoverColor }
                            dropdownLinkColor={ this.props.dropdownLinkColor }
                            dropdownLinkHoverColor={ this.props.dropdownLinkHoverColor }
                          >
                            { item.text }
                          </LinkItem>
                        )
                      } )
                    }
                  </LinkContainer>
                  {
                      renderCta ?
                      renderCta() :
                      (
                        ctaText && ctaUrl &&
                        <PrimaryButtonLink href={ ctaUrl }>{ ctaText }</PrimaryButtonLink>
                      )
                    }
                </MenuContainer>

              </Container>
            </NavWrapper>
          </Wrapper>
        ) }
      </ThemeContext.Consumer>
    )
  }
}
