import { darken, lighten, readableColor } from "polished"
import React from "react"
import styled from "styled-components"

import { Theme, ThemeContext } from "."
import { UnreachableCaseError } from "../helpers/util_helper"

export enum ButtonType {
  PRIMARY = "primary",
  PRIMARY_OUTLINE = "primary_outline",
  SECONDARY = "secondary",
  SECONDARY_OUTLINE = "secondary_outline",
  TERTIARY = "tertiary",
  TERTIARY_OUTLINE = "tertiary_outline",
}


export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  borderColor?: string
  borderColorHover?: string
  color?: string
  colorHover?: string
  textColor?: string
  textColorHover?: string
}

export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: .25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.6rem 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.15s ease;
  vertical-align: middle;
  white-space: nowrap;

  background-color: ${ ( props: ButtonProps ) => props.color || "none" };
  color: ${ ( props: ButtonProps ) => props.textColor || "none" };
  border: 2px solid ${ ( props: ButtonProps ) => props.borderColor || props.color };

  :hover {
    background-color: ${ ( props: ButtonProps ) => props.colorHover || "none" };
    border: 2px solid ${ ( props: ButtonProps ) => props.borderColorHover || props.colorHover || "none" };
    color: ${ ( props: ButtonProps ) => props.textColorHover || props.textColor || "none" };
  }
`

export const getHoverColor = ( color: string, textColor: string ) => {
  const darkColor = darken( 0.2, color )
  const lightColor = lighten( 0.2, color )
  return readableColor( textColor, darkColor, lightColor )
}

const createRenderButton = ( type: ButtonType, as?: "a" ) => (
  ( { children, ...props }: ButtonProps ) => (
    <ThemeContext.Consumer>
    { ( theme: Theme ) => {
      let buttonProps: ButtonProps
      const plainTextColor = "white"

      switch( type ) {
        case ButtonType.PRIMARY:
          buttonProps = {
            color: theme.primaryColor,
            colorHover: getHoverColor( theme.primaryColor!, plainTextColor ),
            textColor: plainTextColor,
          }
          break
        case ButtonType.PRIMARY_OUTLINE:
          buttonProps = {
            borderColor: theme.primaryColor,
            color: "transparent",
            colorHover: theme.primaryColor,
            textColor: theme.primaryColor,
            textColorHover: plainTextColor,
          }
          break
        case ButtonType.SECONDARY:
          buttonProps = {
            color: theme.secondaryColor,
            colorHover: getHoverColor( theme.secondaryColor!, plainTextColor ),
            textColor: plainTextColor,
          }
          break
        case ButtonType.SECONDARY_OUTLINE:
          buttonProps = {
            color: theme.secondaryColor,
            textColor: plainTextColor,
          }
          break
        case ButtonType.TERTIARY:
          buttonProps = {
            color: theme.tertiaryColor,
            colorHover: getHoverColor( theme.tertiaryColor!, plainTextColor ),
            textColor: plainTextColor,
          }
          break
        case ButtonType.TERTIARY_OUTLINE:
          buttonProps = {
            color: theme.tertiaryColor,
            textColor: plainTextColor,
          }
          break

        default:
          throw new UnreachableCaseError( type )
      }

      return (
        <Button
          as={ as }
          { ...buttonProps }
          { ...props as any }
        >
          { children }
        </Button>
      )
     } }
  </ThemeContext.Consumer>
  )
)

export const ButtonLink = ( props: ButtonProps ) => <Button { ...{ as: "a", ...props } as any } />
export const PrimaryButton = createRenderButton( ButtonType.PRIMARY )
export const PrimaryButtonLink = createRenderButton( ButtonType.PRIMARY, "a" )
export const PrimaryOutlinedButton = createRenderButton( ButtonType.PRIMARY_OUTLINE )
export const PrimaryOutlinedButtonLink = createRenderButton( ButtonType.PRIMARY_OUTLINE, "a" )
export const SecondaryButton = createRenderButton( ButtonType.SECONDARY )
export const SecondaryButtonLink = createRenderButton( ButtonType.SECONDARY, "a" )
export const SecondaryOutlinedButton = createRenderButton( ButtonType.SECONDARY_OUTLINE )
export const SecondaryOutlinedButtonLink = createRenderButton( ButtonType.SECONDARY_OUTLINE, "a" )
export const TertiaryButton = createRenderButton( ButtonType.TERTIARY )
export const TertiaryButtonLink = createRenderButton( ButtonType.TERTIARY, "a" )
export const TertiaryOutlinedButton = createRenderButton( ButtonType.TERTIARY_OUTLINE )
export const TertiaryOutlinedButtonLink = createRenderButton( ButtonType.TERTIARY_OUTLINE, "a" )
