import { faFacebookF, faGoogle, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"

interface SocialNavLinkProps {
  color: string
  hoverColor: string
  padding?: string
}

const SocialNavLink = styled.a<SocialNavLinkProps>`
  align-items: center;
  color: ${ ( props ) => props.color || "#fff" };
  display: flex;
  padding: ${ ( props ) => props.padding || "1rem" };

  &:hover {
    color: ${ ( props ) => props.hoverColor || "green" };
  }
`

interface SocialItem {
  socialType: "twitter" | "facebook" | "instagram" | "google" | "email"
  socialUrl: string
}

interface SocialNavProps {
  color: string
  hoverColor: string
  padding?: string
  socialList: SocialItem[]
}

export const SocialNav = ( { socialList, hoverColor, color, padding }: SocialNavProps ) => (
  <div className="flex">
    {
      socialList.map( ( socialItem, i ) => {
        return (
          <SocialNavLink
            color={ color }
            hoverColor={ hoverColor }
            padding={ padding }
            key={ i }
            href={ socialItem.socialUrl }
            target="_blank"
          >
            { socialItem.socialType === "twitter" && <FontAwesomeIcon size="lg" icon={ faTwitter } /> }
            { socialItem.socialType === "facebook" && <FontAwesomeIcon size="lg" icon={ faFacebookF } /> }
            { socialItem.socialType === "google" && <FontAwesomeIcon size="lg" icon={ faGoogle } /> }
            { socialItem.socialType === "instagram" && <FontAwesomeIcon size="lg" icon={ faInstagram } /> }
            { socialItem.socialType === "email" && <FontAwesomeIcon size="lg" icon={ faEnvelope } /> }
          </SocialNavLink>
        )
          } )
    }
  </div>
)
