import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img, { FluidObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"

const AccordionItemContainer = styled.div.attrs( {
  className: "Accordion_ItemContainer",
} )`

`

const AccordionHeaderImgContainer = styled.div.attrs( {
  className: "Accordion_HeaderImgContainer"
} )`
  padding: 30px 0;
  width: 100%;
`

// tslint:disable-next-line: space-within-parens
const AccordionHeaderImg = styled(Img).attrs( {
  className: "Accordion_HeaderImg"
} )`
  width: 100%;
`

const AccordionItemHeader = styled.div.attrs( {
  className: "Accordion_ItemHeader",
} )`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  justify-content: space-between;
  padding: 20px 25px;
`

// tslint:disable-next-line: space-within-parens
const AccordionItemHeaderIcon = styled(FontAwesomeIcon).attrs( {
  className: "Accordion_ItemHeaderIcon",
  icon: faAngleDown,
  size: "lg",
} )`
  margin-left: 5px;
  transition: transform 0.5s;
`

const AccordionItemContentWrapper = styled.div.attrs( {
  className: "Accordion_ItemContentWrapper",
} )`
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s;
`

const AccordionItemContentContainer = styled.div.attrs( {
  className: "Accordion_ItemContentContainer",
} )``

const AccordionItemContent = styled.div.attrs( {
  className: "Accordion_ItemContent",
} )`
  padding: 0px 25px 20px 25px;
`

const AccordionItemContentText = styled.p.attrs( {
  className: "Accordion_ItemContentText"
} )`
  margin: 0;

  & + & {
    margin-top: 10px;
  }
`

const AccordionParagraph = styled.div.attrs( {
  className: "Accordion_Paragraph"
} )`
  margin-bottom: 20px;
`

const AccordionParagraphTitle = styled.h2.attrs( {
  className: "Accordion_ParagraphTitle"
} )`
  margin-top: 10px;
`

const AccordionParagraphSubTitle = styled.h3.attrs( {
  className: "Accordion_ParagraphSubTitle"
} )`
  margin-top: 10px;
`

const AccordionParagraphText = styled.p.attrs( {
  className: "Accordion_ParagraphText"
} )`
  margin-top: 10px;
`

export interface AccordionItemProps {
  contentStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  headerImg?: FluidObject
  headerStyle?: React.CSSProperties
  id: string
  isOpen: boolean
  onToggle: ( id: string ) => void
  paragraphs?: ParagraphProps[]
  paragraphTitleStyle?: React.CSSProperties
  paragraphSubtitleStyle?: React.CSSProperties
  paragraphTextStyle?: React.CSSProperties
  text?: string[] | string
  title: string
}

interface ParagraphProps {
  paragraphSubTitle?: string
  paragraphText?: string | string[]
  paragraphTitle?: string
  paragraphRenderContent?: () => React.ReactNode
}

interface AccordionItemState {
  contentHeight: number
}

export class AccordionItem extends React.Component<AccordionItemProps, AccordionItemState> {
  contentElement?: HTMLDivElement
  contentWrapperElement?: HTMLDivElement

  state = { contentHeight: 0 }

  componentDidMount() {
    window.addEventListener( "resize", this.handleResize )
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.handleResize )
  }

  handleResize = () => {
    this.updateContentHeight()
  }

  setContentWrapperElement = ( element: HTMLDivElement ) => {
    this.contentWrapperElement = element
    this.updateContentHeight()
  }

  setContentElement = ( element: HTMLDivElement ) => {
    this.contentElement = element
    this.updateContentHeight()
  }

  updateContentHeight = () => {
    if( this.contentElement ) {
      this.setState( { contentHeight: this.contentElement.scrollHeight } )
    } else {
      this.setState( { contentHeight: 0 } )
    }
  }

  toggleOpen = () => {
    this.props.onToggle( this.props.id )
  }

  render() {
    const { containerStyle, contentStyle, headerImg, headerStyle, isOpen, paragraphs, paragraphSubtitleStyle, paragraphTextStyle, paragraphTitleStyle, text, title } = this.props
    const { contentHeight } = this.state

    const contentWrapperStyle: React.CSSProperties = {
      height: isOpen ? `${ contentHeight }px` : "0px",
      opacity: isOpen ? 1 : 0,
    }

    const iconStyle: React.CSSProperties = {
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
    }

    return (
      <AccordionItemContainer style={ containerStyle }>
        <AccordionItemHeader onClick={ this.toggleOpen } style={ { ...headerStyle, transition: "0.5s" } }>
          { title }
          <AccordionItemHeaderIcon style={ iconStyle }/>
        </AccordionItemHeader>
        <AccordionItemContentWrapper
          aria-hidden={ isOpen ? "true" : "false" }
          style={ contentWrapperStyle }
          ref={ this.setContentWrapperElement }
        >
          <AccordionItemContentContainer ref={ this.setContentElement }>
            { headerImg ? <AccordionHeaderImgContainer> <AccordionHeaderImg fluid={ headerImg } /> </AccordionHeaderImgContainer> : null }
            <AccordionItemContent style={ contentStyle }>
              { text ? (
                Array.isArray( text ) ? text.map( ( textItem, i ) => (
                  <AccordionItemContentText key={ i }>{ textItem }</AccordionItemContentText>
                ) ) : text
              ) : null
              }
              { paragraphs ? (
                Array.isArray( paragraphs ) ? paragraphs.map( ( paragraphItem, j ) => (
                  <>
                    <AccordionParagraph key={ j }>
                      <AccordionParagraphTitle style={ paragraphTitleStyle }>{ paragraphItem.paragraphTitle }</AccordionParagraphTitle>
                      <AccordionParagraphSubTitle style={ paragraphSubtitleStyle }>{ paragraphItem.paragraphSubTitle }</AccordionParagraphSubTitle>
                      { Array.isArray( paragraphItem.paragraphText ) ? paragraphItem.paragraphText.map( ( paragraph, k ) => (
                        <AccordionParagraphText style={ paragraphTextStyle } key={ k }>{ paragraph }</AccordionParagraphText>
                      ) ) : <AccordionParagraphText style={ paragraphTextStyle }>{ paragraphItem.paragraphText }</AccordionParagraphText>
                      }
                      { paragraphItem.paragraphRenderContent ? paragraphItem.paragraphRenderContent() : null }
                    </AccordionParagraph>
                  </>
                ) ) : paragraphs
              ) : null
              }
            </AccordionItemContent>
          </AccordionItemContentContainer>
        </AccordionItemContentWrapper>
      </AccordionItemContainer>
    )
  }
}



export interface AccordionProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  headerStyle?: React.CSSProperties
  headerStyleOpen?: React.CSSProperties
  contentStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  items: Array<{
    content?: string[] | string
    headerImg?: FluidObject
    paragraph?: ParagraphProps[]
    title: string
  }>
  paragraphTitleStyle?: React.CSSProperties
  paragraphSubtitleStyle?: React.CSSProperties
  paragraphTextStyle?: React.CSSProperties
  style?: React.CSSProperties
}

interface AccordionState {
  openId: string | null

}

export class Accordion extends React.Component<AccordionProps, AccordionState> {
  state = { openId: null }

  onToggle = ( id: string ) => {
    if( this.state.openId === id ) {
      this.setState( { openId: null } )
    } else {
      this.setState( { openId: id } )
    }
  }

  render() {
    const { openId } = this.state
    const { containerStyle, contentStyle, headerStyle, headerStyleOpen, items, paragraphSubtitleStyle, paragraphTextStyle, paragraphTitleStyle, ...otherProps } = this.props

    return (
      <div { ...otherProps as any }>
        {
          items.map( ( item, i ) => {
            const id = `${ i }`

            return (
              <AccordionItem
                containerStyle={ containerStyle }
                contentStyle={ contentStyle }
                headerImg={ item.headerImg }
                headerStyle={ openId === id ? { ...headerStyle, ...headerStyleOpen } : headerStyle }
                id={ id }
                isOpen={ id === openId }
                key={ id }
                onToggle={ this.onToggle }
                paragraphs={ item.paragraph }
                paragraphSubtitleStyle={ paragraphSubtitleStyle }
                paragraphTextStyle={ paragraphTextStyle }
                paragraphTitleStyle={ paragraphTitleStyle }
                text={ item.content }
                title={ item.title }
              />
            )
           } )
        }
      </div>
    )
  }
}
