import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { MEDIUM_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"


const VideoDisplayContainer = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,0.75);
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`

const PositioningContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  min-width: 95%;
  position: relative;

  @media ${ MEDIUM_SCREEN_MEDIA_QUERY}  {
    min-width: 60%
  }
`

// Styling for video to maintain aspect ratio

const EmbedResponsive = styled.div`
  display: block;
  height: 0px;
  overflow: hidden;
  padding: 0;
  padding-bottom: 56.25%;
  position: relative;
`

const VideoIframe = styled.iframe`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

interface VideoDisplayProps {
  closeVideoDisplay: () => void
  isVideoDisplayed: boolean
  videoUrl: string
}

export const VideoDisplay: FunctionComponent<VideoDisplayProps> = ( { closeVideoDisplay, videoUrl, isVideoDisplayed } ) => {

  if ( !isVideoDisplayed || !videoUrl ) {
    return null
  }

  return (
    <VideoDisplayContainer onClick={ closeVideoDisplay }>
      <PositioningContainer>
        <EmbedResponsive>
          <VideoIframe src={ videoUrl } />
        </EmbedResponsive>
      </PositioningContainer>
    </VideoDisplayContainer>
  )
}
