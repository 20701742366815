import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { Slider } from "."
import { LARGE_SCREEN_MEDIA_QUERY, MEDIUM_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"


const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  position: relative;

  @media ${ LARGE_SCREEN_MEDIA_QUERY }{
    flex-direction: row;
  }
`

// tslint:disable-next-line: space-within-parens
const SlideImgContainer = styled.div<SlideProps>`
  background-image: url( ${ ( props: SlideProps ) => props.image } );
  background-position: ${ ( props: SlideProps ) => props.backgroundPosition || "center" };
  background-size: ${ ( props: SlideProps ) => props.backgroundSize || "cover" };
  height: 350px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    height: auto;
  }
`

interface SlideContentContainerProps {
  background?: string
}

const SlideContentContainer = styled.div<SlideContentContainerProps>`
  align-items: center;
  background: ${ ( props: SlideContentContainerProps ) => props.background || "black" };
  display: flex;
  flex-direction: column;
  min-height: 350px;
  justify-content: center;
  padding: 0 5%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    align-items: flex-start;
    min-height: none;
  }
`

const SubTitle = styled.h3`
  color: white;
  font-size: 1.625rem;
  font-weight: 900;
  line-height: 1.25;
  margin-bottom: 1rem;

  @media ${ MEDIUM_SCREEN_MEDIA_QUERY } {
    font-size: 3rem;
  }
`

const Text = styled.p`
  color: white;
  margin-bottom: 2rem;
`

interface SlideProps {
  backgroundPosition?: string
  backgroundSize?: string
  contentBackground?: string
  image: string
  renderCTA?: () => React.ReactNode
  renderSlideContent?: () => React.ReactNode
  subTitle?: string
  text?: string
  title?: string
}

const Slide: FunctionComponent<SlideProps> = ( { backgroundPosition, backgroundSize, contentBackground, image, renderCTA, renderSlideContent, subTitle, text, title} ) => (
  <>
    <SlideContainer>
      <SlideImgContainer image={ image } backgroundPosition={ backgroundPosition }  backgroundSize={ backgroundSize } className="lg:w-1/2"/>
      <SlideContentContainer background={ contentBackground } className="lg:w-1/2 text-center lg:text-left">
        { subTitle ? <SubTitle>{ subTitle }</SubTitle> : null }
        { text ? <Text>{ text }</Text> : null }
        { renderSlideContent ? renderSlideContent() : null }
        { renderCTA ? renderCTA() : null }
      </SlideContentContainer>
    </SlideContainer>
  </>
)

interface ResponsiveImageSliderProps {
  contentBackground?: string
  chevronColor?: string
  chevronInside?: boolean
  renderCTA?: () => React.ReactNode
  responsive?: Array<{
    breakpoint: number,
    slidesToShow: number
  }>
  slides: SlideProps[]
}

export const ResponsiveImageSlider: FunctionComponent<ResponsiveImageSliderProps> = ( { contentBackground, chevronColor, chevronInside, renderCTA, responsive, slides } ) => {

  return (
    <Slider
      autoplay={ true }
      chevronColor={ chevronColor ? chevronColor : "#33333" }
      chevronInside={ chevronInside }
      dotsClass="Slider_ResponsiveImageDots"
      responsive={ responsive }
      slides={ slides.map( ( slide, i ) => (
        <Slide
          backgroundPosition={ slide.backgroundPosition }
          backgroundSize={ slide.backgroundSize }
          contentBackground={ contentBackground }
          image={ slide.image }
          key={ i }
          renderCTA={ renderCTA }
          renderSlideContent={ slide.renderSlideContent }
          subTitle={ slide.subTitle }
          text={ slide.text }
          title={ slide.title }
        />
      ) ) }
      slidesToShow={ 1 }
    />
  )
}
