import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ThemeContext } from "."
import { isGreaterThanExtraLargeScreen, isGreaterThanMediumScreen, LARGE_SCREEN_MEDIA_QUERY } from "../helpers/responsive_helpers"


interface LinkContainerProps extends React.HTMLProps<HTMLDivElement> {
  align?: "left" | "center" | "right" | "spread"
  mobile?: boolean
}

const LinkContainer = styled.div.attrs( {
  className: "NavBar_LinkContainer"
} ) <LinkContainerProps>`
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${
  ( props ) => {
    switch ( props.align ) {
      case "left":
        return "flex-start"
      case "right":
        return "flex-end"
      case "spread":
        return "space-between"
      default:
        return "center"
    }
  }
  };
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY}  {
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    text-align: inherit;
  }
`

const LinkItemWrapper = styled.div.attrs( {
  className: "NavBar_LinkItemWrapper"
} )`
  position: relative;
`

interface LinkItemDropdownProps {
  backgroundColor?: string
}

const LinkItemDropdown = styled.div.attrs( {
  className: "NavBar_LinkItemDropdown"
} ) <LinkItemDropdownProps>`
  background-color: ${ ( props ) => props.backgroundColor || "transparent"};
  border-radius: .25rem;
  font-size: 1rem;
  left: 0;
  min-width: 10rem;
  padding: .5rem 0;
  top: 100%;
  z-index: 1000;

  @media ${ LARGE_SCREEN_MEDIA_QUERY}  {
    box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.25);
    margin-top: -5px;
    position: absolute;
  }
`

interface LinkItemDropdownLinkProps {
  backgroundColor?: string
  backgroundHoverColor?: string
  color?: string
  hoverColor?: string
}

// tslint:disable-next-line: space-within-parens
const LinkItemDropdownLink = styled(Link).attrs( {
  className: "NavBar_LinkItemDropdownLink"
} ) <LinkItemDropdownLinkProps>`
  background-color: transparent;
  border: 0;
  color: ${ ( props ) => props.color || "black"};
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;

  :hover {
    background-color: ${ ( props ) => props.backgroundHoverColor || "transparent"};
    color: ${ ( props ) => props.hoverColor || "inherit"};
  }
`

const LinkItemDropdownLinkExternal = styled.a.attrs( {
  className: "NavBar_LinkItemDropdownLinkExternal"
} ) <LinkItemDropdownLinkProps>`
  background-color: transparent;
  border: 0;
  color: ${ ( props ) => props.color || "black"};
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;

:hover {
  background-color: ${ ( props ) => props.backgroundHoverColor || "transparent"};
  color: ${ ( props ) => props.hoverColor || "inherit"};
}
`

interface LinkItemLinkProps {
  backgroundHoverColor?: string
  color?: string
  hoverColor?: string
}

// tslint:disable-next-line: space-within-parens
const LinkItemLink = styled(Link).attrs( {
  className: "NavBar_LinkItemLink"
} ) <LinkItemLinkProps>`
  color: ${ ( props ) => props.color || "black"};
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.75rem 1rem;
  text-decoration: none;

  :hover {
    background-color: ${ ( props ) => props.backgroundHoverColor || "transparent"};
    color: ${ ( props ) => props.hoverColor || "inherit"};
    text-decoration: underline;
  }
`

interface NavBarLinkProps {
  color?: string
  dropdownBackgroundColor?: string
  dropdownLinkBackgroundHoverColor?: string
  dropdownLinkColor?: string
  dropdownLinkHoverColor?: string
  hoverColor?: string
  item: NavBarLinkItem
}

interface NavBarLinkState {
  isLargeScreen?: boolean
  isMobile?: boolean
  isOpen?: boolean
}

class NavBarLink extends React.PureComponent<NavBarLinkProps, NavBarLinkState> {
  constructor( props: NavBarLinkProps ) {
    super( props )
    this.state = {
      isLargeScreen: true,
      isMobile: false,
      isOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener( "resize", this.handleResize )
    this.setState( {
      isLargeScreen: this.getIsLargeScreen()
    } )
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.handleResize )
  }

  handleResize = () => {
    this.setState( {
      isLargeScreen: this.getIsLargeScreen()
    } )
  }

  setIsOpen = ( value: boolean ) => {
    const { item } = this.props
    const { childs } = item
    if ( !childs || childs.length === 0 ) {
      return
    }

    this.setState( { isOpen: value } )
  }

  openDropdown = () => {
    this.setIsOpen( true )
  }

  closeDropdown = () => {
    this.setIsOpen( false )
  }

  toggleDropdown = () => {
    this.setState( ( prevState ) => ( { isOpen: !( prevState.isOpen ) } ) )
  }

  getIsMobile = () => {
    return !isGreaterThanMediumScreen()
  }

  getIsLargeScreen = () => {
    return isGreaterThanExtraLargeScreen()
  }

  render() {
    const { dropdownBackgroundColor, item } = this.props
    const { isMobile, isOpen } = this.state
    const { childs, text, url } = item

    const containerProperties = {
      onMouseEnter: this.openDropdown,
      onMouseLeave: this.closeDropdown,
    }

    if ( !this.state.isLargeScreen && item.isOverFlowItem ) { return null }
    if ( this.state.isLargeScreen && item.isOverFlowMenu ) { return null }

    return (
      <LinkItemWrapper { ...containerProperties }>
        <LinkItemLink
          to={ url || "#" }
          color={ this.props.color }
          backgroundHoverColor={ isMobile ? this.props.dropdownLinkBackgroundHoverColor : undefined }
          hoverColor={ this.props.hoverColor }
        >
          { text }
        </LinkItemLink>
        {
          childs && isOpen && (
            <LinkItemDropdown backgroundColor={ dropdownBackgroundColor }>
              {
                childs.map( ( child, i ) => {
                  if( child.isExternal ) {
                    return (
                      <LinkItemDropdownLinkExternal
                        key={ i }
                        href={ child.url || "#" }
                        target="_blank"
                        backgroundHoverColor={ this.props.dropdownLinkBackgroundHoverColor }
                        color={ this.props.dropdownLinkColor }
                        hoverColor={ this.props.dropdownLinkHoverColor }
                      >
                        { child.text }
                      </LinkItemDropdownLinkExternal>
                    )
                  } else {
                    return (
                      <LinkItemDropdownLink
                        key={ i }
                        to={ child.url || "#" }
                        backgroundHoverColor={ this.props.dropdownLinkBackgroundHoverColor }
                        color={ this.props.dropdownLinkColor }
                        hoverColor={ this.props.dropdownLinkHoverColor }
                      >
                        { child.text }
                      </LinkItemDropdownLink>
                    )
                  }
                 } )
              }
            </LinkItemDropdown>
          )
        }
      </LinkItemWrapper>
    )
  }
}

interface NavBarBase {
  isExternal?: boolean
  text: string
  url?: string
}

interface NavBarLinkItem extends NavBarBase {
  childs?: NavBarBase[]
  isOverFlowItem?: boolean
  isOverFlowMenu?: boolean
}

interface NavBarLinksProps {
  align?: "left" | "center" | "right" | "spread"
  dropdownBackgroundColor?: string
  dropdownLinkBackgroundHoverColor?: string
  dropdownLinkColor?: string
  dropdownLinkHoverColor?: string
  items: NavBarLinkItem[]
  linkColor?: string
  linkHoverColor?: string
  mobile?: boolean
}

export const NavBarLinks = ( { align, items, linkColor, dropdownBackgroundColor, linkHoverColor, dropdownLinkBackgroundHoverColor, dropdownLinkColor, dropdownLinkHoverColor, mobile }: NavBarLinksProps ) => {
  return (
    <ThemeContext.Consumer>
      { ( theme ) => (
        <LinkContainer align={ align } mobile={ mobile }>
          {
            items.map( ( item, i ) => {
              return (
                <NavBarLink
                  key={ i }
                  item={ item }
                  color={ linkColor || theme.primaryColor }
                  hoverColor={ linkHoverColor }
                  dropdownBackgroundColor={ dropdownBackgroundColor || "#fff" }
                  dropdownLinkBackgroundHoverColor={ dropdownLinkBackgroundHoverColor }
                  dropdownLinkColor={ dropdownLinkColor }
                  dropdownLinkHoverColor={ dropdownLinkHoverColor }
                >
                  { item.text }
                </NavBarLink>
              )
            } )
          }
        </LinkContainer>
      ) }
    </ThemeContext.Consumer>
  )
}
